import { Model } from '@app/interfaces';
import { Column } from './column.class';
import { Employee } from '@app/models/employee/employee.model';

const DEFAULT_OVERFLOW_LIMIT = 3;
export abstract class MultiAvatarColumn<T extends Model> extends Column {
    /**
     * When to start truncate the amount of people to display
     */
    overflowLimit = DEFAULT_OVERFLOW_LIMIT;

    /**
     * Retrieve the employees from the row (model) to be displayed
     */
    abstract getEmployees(row: T): Employee[];

    getEmployeesToShow(row: T): Employee[] {
        return this.getEmployees(row).slice(0, this.overflowLimit);
    }

    hasOverflow(row: T): boolean {
        return this.overflowCount(row) > 0;
    }

    overflowCount(row: T): number {
        return this.getEmployees(row).length - this.overflowLimit;
    }
}
